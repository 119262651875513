import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

function ContactForm() {
	const [state, handleSubmit] = useForm("mayppkrg");

	return (
		<React.Fragment>
			{state.succeeded && (
				<>
					<Alert
						variant="success"
						style={{ backgroundColor: "#D1E6DD", border: "1px solid #A3CFBB" }}
					>
						Form Submitted Successfully!
					</Alert>
					<div className="d-none">
						{setTimeout(() => (window.location = "/thank-you"), 500)}
					</div>
				</>
			)}

			<Form className="w-100" onSubmit={handleSubmit}>
				<p className="fw-bold text-center">CONTACT US</p>
				<Form.Group className="mb-3" controlId="name">
					<p className="mb-1">Name</p>
					<Form.Control name="name" placeholder="Name" type="text" size="lg" />
				</Form.Group>
				<ValidationError prefix="Name" field="name" errors={state.errors} />
				<Form.Group className="mb-3" controlId="email">
					<p className="mb-1">Email</p>
					<Form.Control
						size="lg"
						name="_replyto"
						type="email"
						placeholder="Email Address"
					/>
				</Form.Group>
				<ValidationError prefix="Email" field="email" errors={state.errors} />
				<Form.Group className="mb-3" controlId="telephone">
					<p className="mb-1">Phone</p>
					<Form.Control type="tel" name="phone" placeholder="Phone" size="lg" />
				</Form.Group>

				<ValidationError prefix="Phone" field="phone" errors={state.errors} />
				<Form.Group className="mb-3" controlId="message">
					<p className="mb-1">Message</p>
					<Form.Control
						name="message"
						placeholder="Message"
						as="textarea"
						rows={4}
					/>
				</Form.Group>

				<ValidationError
					prefix="Message"
					field="message"
					errors={state.errors}
				/>

				{/* <div
        class="g-recaptcha"
        data-sitekey=""
      ></div> */}
				<div className="my-3 text-black-50 text-justify">
					<p>
						You voluntarily choose to provide personal details to us via this
						website. Personal information will be treated as confidential by us
						and held in accordance with the Data Protection Act 1998. You agree
						that such personal information may be used to provide you with the
						details of services and products in writing, by email or by
						telephone.
					</p>
					<p>
						By submitting this information you have given your agreement to
						receive contact from us to discuss your mortgage requirements.
					</p>
				</div>
				<Button
					size="lg"
					className="text-black w-100 rounded-1 border-0 "
					style={{ backgroundColor: "#F5BBA0" }}
					type="submit"
					id="contact-send-btn"
					disabled={state.submitting}
				>
					SEND
				</Button>
			</Form>
		</React.Fragment>
	);
}
export default ContactForm;
