import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React, { useRef } from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import ContactForm from "../components/contactForm";

const IndexPage = () => {
	const tl = useRef();
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Robbins Financial"
				description="New Website Coming Soon!"
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: "Robbins Financial",
					description: "New Website Coming Soon!",
				}}
			/>

			<div>
				<Layout>
					<Container className="mt-lg-10  mt-4">
						<Row className="g-5">
							<Col sm={12} lg={6}>
								<StaticImage
									src="../images/logo.png"
									style={{ maxWidth: "250px", maxHeight: "100px" }}
								/>
								<h1
									className="display-2 fw-bolder "
									style={{ color: "#7CD2F4" }}
								>
									New Website Coming Soon!
								</h1>
								<p className="text-muted display-6">Want to get in touch?</p>
								<Row>
									<Col>
										<p className="text-muted">
											Phone Number:{" "}
											<a href="tel:07724452972" className="black-link fw-bold ">
												07724452972
											</a>
										</p>
									</Col>
									<Col>
										<p className="text-muted ">
											Email:{" "}
											<a
												href="mailto:zoe@robbinsfinancial.co.uk"
												className=" black-link"
											>
												zoe@robbinsfinancial.co.uk
											</a>
										</p>
									</Col>
								</Row>
							</Col>
							<Col lg={6} sm={12}>
								<ContactForm />
							</Col>
						</Row>
						<p
							className="text-center text-black-50 "
							style={{ marginTop: 120 }}
						>
							© Copyrights Robbins Financial | All Rights Reserved
						</p>
					</Container>
				</Layout>
			</div>
		</>
	);
};

export default IndexPage;
